import React from 'react'
import GeneralLayout from '../layouts/general'

class RegularPage extends React.Component {
    render() {
        console.log('Page props', this.props)
        return <GeneralLayout
            extraClass={this.props.pageContext.slug + ' ' + this.props.pageContext.type.toLowerCase()}
            pageTitle={this.props.pageContext.title}
            sections={this.props.pageContext.section}/>
    }
}

export default RegularPage
